import React, { useContext } from "react"
import styled, { ThemeContext } from "styled-components"
import BgImage from "gatsby-background-image"

import useWindowWidth from "../../utilities/hooks/useWindowWidth"
import { Button } from "../atoms/Button"

const HeroLayout = ({ className, heroHeading, heroText, heroButtonTxt, heroButtonLink, heroBackground }) => {
  const { mobileBreakpoint } = useContext(ThemeContext)
  const width = useWindowWidth(mobileBreakpoint)
  const opacity = width >= mobileBreakpoint ? [".8", "0"] : ["0.6", "0.4"]

  const imageStack = [
    `linear-gradient(90deg, rgba(0, 0, 0, ${opacity[0]}), rgba(0, 0, 0,  ${opacity[1]}))`,
    heroBackground,
  ]

  return (
    <BgImage Tag="section" className={className} fluid={imageStack}>
      <div className="hero__inner">
        <div className="hero__content-container">
          <h1 className="hero__heading">{heroHeading}</h1>
          {heroText && <div className="hero__text" dangerouslySetInnerHTML={{ __html: heroText }} />}
          {heroButtonTxt && <Button link={heroButtonLink}>{heroButtonTxt}</Button>}
        </div>
      </div>
    </BgImage>
  )
}

const Hero = styled(HeroLayout)`
  width: 100%;
  min-height: 400px;
  display: flex;
  justify-content: center;
  padding: 2rem 0;
  .hero__inner {
    max-width: ${({ theme }) => theme.maxWidth}px;
    width: 100%;
    display: flex;
    align-items: center;
    .hero__content-container {
      padding: 1rem;
      color: ${({ theme }) => theme.light};
      .hero__text {
        margin-bottom: 2rem;
      }
    }
  }

  @media (min-width: ${({ theme }) => theme.mobileBreakpoint}px) {
    .hero__inner {
      .hero__content-container {
        padding: 2rem;
        width: 50%;
      }
    }
  }
`

export default Hero
